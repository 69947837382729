<template>
  <div class="zoom-container">
    <img :src="src" :alt="alt" v-auth-image="src" ref="img" @click="zoom" />

    <div v-if="zoomed" class="zoom-popup" @click="zoomed = false">
      <img :src="zoomSrc" :alt="alt" v-auth-image="src" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageZoom',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: [String, Number],
      default: 'img',
    },
  },
  data() {
    return {
      zoomed: false,
      zoomSrc: false,
    };
  },
  methods: {
    zoom() {
      this.zoomSrc = this.$refs.img.src;
      this.zoomed = true;
    },
  },
};
</script>

<style scoped lang="scss">
.zoom-container {
  display: inline-block;
}

img {
  cursor: zoom-in;
  max-width: 100%;
  max-height: 100%;
}

.zoom-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: zoom-out;
  }
}
</style>
