<template>
  <div ref="chat-messages" class="chat-messages">
    <div class="chat-conversation px-3 pt-3">
      <ul class="list-unstyled mb-0">
        <div class="text-center">
          <b-spinner v-if="messagesLoading"></b-spinner>
        </div>
        <!-- <li>
          <div class="chat-day-title">
            <span class="title">Today</span>
          </div>
        </li> -->
        <ChatMessage v-for="data of messages" :key="data.message" :message="data" />
      </ul>
    </div>
  </div>
</template>

<script>
import ChatMessage from '@/components/pages/chat/chat-message';

export default {
  name: 'ChatMessages',
  components: {
    ChatMessage,
  },
  props: {
    messagesLoading: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    scrollToBottom() {
      this.$refs['chat-messages'].scroll({ top: this.$refs['chat-messages'].scrollHeight, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-messages {
  flex-grow: 1;
  background: white;
  height: 100%;
  overflow: auto;
}
</style>
