<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import ChatHeader from '@/components/pages/chat/chat-header';
import ChatMessages from '@/components/pages/chat/chat-messages';
import ChatFileUploader from '@/components/pages/chat/chat-file-uploader';
import { authComputed } from '@/state/helpers';
import { validationMixin } from '@/mixins/form/validation';
import { mapState } from 'vuex';
import VueFeather from 'vue-feather';
const MAX_FILES_PER_MESSAGE = 10;

export default {
  mixins: [validationMixin],
  page: {
    title: 'Chat',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    ChatHeader,
    ChatMessages,
    ChatFileUploader,
    VueFeather,
  },
  data() {
    return {
      message: '',
      chatAttachments: [],
      loading: {
        sendMessage: false,
      },
    };
  },
  computed: {
    ...authComputed,
    ...mapState('broadcasting', ['echo']),
    chat() {
      return this.$store.state.chat.chat;
    },
    disabledSubmit() {
      return (
        (this.loading.sendMessage || !this.message.length || this.message.length > 10000) &&
        !this.chatAttachments.length
      );
    },
  },
  methods: {
    deleteAttachment(index) {
      this.chatAttachments = [...this.chatAttachments.slice(0, index), ...this.chatAttachments.slice(index + 1)];
    },
    onFileAdded(files) {
      if (this.chatAttachments.length + files.length >= MAX_FILES_PER_MESSAGE) {
        this.$notify({
          title: this.$t('Chat.AttachLimitReached'),
          icon: 'AlertCircleIcon',
          type: 'warn',
          text: this.$t('Chat.CanNotUploadMore', { amount: 10 }),
        });
      }

      const availableSpace = MAX_FILES_PER_MESSAGE - this.chatAttachments.length;

      if (availableSpace < files.length) {
        files.length = availableSpace;
      }

      this.chatAttachments.push(...files);
    },
    formSubmit() {
      if (this.loading.sendMessage || (!this.message && !this.chatAttachments.length)) {
        return;
      }

      this.loading.sendMessage = true;

      const formData = new FormData();

      this.chatAttachments.forEach((attach) => {
        formData.append('attachments[]', attach);
      });

      if (this.message) {
        formData.append('text', this.message);
      }

      this.axios
        .post('/chat', formData)
        .then(({ data }) => {
          this.$store.commit('chat/ADD_MESSAGE', { ...data, is_own: true });
          this.message = '';
          this.chatAttachments = [];
          this.scrollToBottom();
        })
        .catch((error) => {
          const errorCode = this.handleResponseErrors(error.response, {});

          this.$notify({
            type: 'error',
            text: this.$t(`ResponseErrors.${errorCode}`),
          });
        })
        .finally(() => {
          this.loading.sendMessage = false;
        });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs['chat-messages']?.scrollToBottom();
      });
    },
    onMessageInput: function () {
      this.$broadcasting.echo.private(`chat-rooms.${this.chat.id}.common`).whisper('typing', {
        name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
      });
    },
  },
  mounted() {
    this.$store.dispatch('chat/initChat');
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  },
  metaInfo() {
    return {
      title: this.$t('Chat.Title'),
    };
  },
};
</script>

<template>
  <Layout>
    <div class="chat-page">
      <div class="w-100 user-chat">
        <ChatHeader />

        <ChatMessages ref="chat-messages" :messages="chat?.messages" :messagesLoading="loading.messages" />

        <div class="p-3 chat-input-section">
          <ul class="attach-list">
            <li class="attach-list__item" v-for="(attach, i) in chatAttachments" :key="i">
              <span class="me-2">
                {{ attach.name }}
              </span>
              <vue-feather
                type="x"
                stroke="#666597"
                size="18"
                class="cursor-pointer"
                @click="deleteAttachment(i)"
              ></vue-feather>
            </li>
          </ul>
          <form @submit.prevent="formSubmit" class="d-flex align-items-center">
            <ChatFileUploader @file-added="onFileAdded" class="me-2" />
            <div class="me-2 flex-grow-1">
              <div class="position-relative">
                <input
                  type="text"
                  v-model="message"
                  class="form-control chat-input rounded"
                  :placeholder="$t('Chat.EnterMessage')"
                  @input="onMessageInput"
                />
                <!-- <div class="chat-input-links">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" v-b-tooltip.hover placement="top" title="Emoji">
                        <i class="mdi mdi-emoticon-happy-outline"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" v-b-tooltip.hover placement="top" title="Images">
                        <i class="mdi mdi-file-image-outline"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" v-b-tooltip.hover placement="top" title="Add Files">
                        <i class="mdi mdi-file-document-outline"></i>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
            <div class="col-auto">
              <button :disabled="disabledSubmit" type="submit" class="btn btn-primary btn-rounded chat-send w-md">
                <span class="d-none d-sm-inline-block me-2">{{ $t('Chat.Send') }}</span>
                <i class="mdi mdi-send send-icon"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.user-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-input-section {
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.chat-page {
  height: calc(100dvh - 114px);
  margin-bottom: -40px;
}

.attach-list {
  margin: 0 0 6px 0;
  list-style: none;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
}
</style>

<style>
.app-rtl .send-icon {
  transform: rotate(180deg);
  display: inline-flex;
}
</style>
