<template>
  <div class="chat-header p-3 border-bottom">
    <div class="row">
      <div class="col-md-4 col-9">
        <h5 class="font-size-15 mb-1">{{ $t('Chat.SupportAgent') }}</h5>
        <p class="text-muted mb-0">
          <!-- <i class="mdi mdi-circle text-success align-middle me-1"></i> -->
          <!-- Active now -->
        </p>
      </div>
      <div class="col-md-8 col-3">
        <ul class="list-inline user-chat-nav text-end mb-0">
          <!-- <li class="list-inline-item d-none d-sm-inline-block">
            <b-dropdown menu-class="dropdown-menu-md dropdown-menu-end" variant="white" right toggle-class="nav-btn">
              <template v-slot:button-content>
                <i class="bx bx-search-alt-2"></i>
              </template>
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="submit">
                        <i class="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-dropdown>
          </li> -->

          <!-- <li class="list-inline-item d-none d-sm-inline-block">
            <b-dropdown toggle-class="nav-btn" menu-class="dropdown-menu-end" right variant="white">
              <template v-slot:button-content>
                <i class="bx bx-cog"></i>
              </template>
              <b-dropdown-item>View Profile</b-dropdown-item>
              <b-dropdown-item>Clear chat</b-dropdown-item>
              <b-dropdown-item>Muted</b-dropdown-item>
              <b-dropdown-item>Delete</b-dropdown-item>
            </b-dropdown>
          </li> -->

          <!-- <li class="list-inline-item">
            <b-dropdown toggle-class="nav-btn" menu-class="dropdown-menu-end" right variant="white">
              <template v-slot:button-content>
                <i class="bx bx-dots-horizontal-rounded"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else</b-dropdown-item>
            </b-dropdown>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chat-header {
  background: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>
