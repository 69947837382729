<template>
  <label class="mb-0 d-flex" v-b-tooltip.hover :title="$t('Chat.Supported')">
    <vue-feather type="file" stroke="#666597" size="20" class="cursor-pointer"></vue-feather>
    <input
      ref="file-input"
      type="file"
      class="chat-file-upload__input"
      name="profile_pic"
      multiple
      :accept="filesExt.join(',')"
      @input="onInput"
    />
  </label>
</template>

<script>
import VueFeather from 'vue-feather';
import Swal from 'sweetalert2';

export default {
  name: 'ChatFileUploader',
  components: {
    VueFeather,
  },
  data() {
    return {
      filesExt: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
      maxFileSize: 5 * 1024 * 1024,
    };
  },
  methods: {
    onInput(event) {
      const validFiles = [...event.target.files].filter((file) => {
        return this.filesExt.includes(file.type) && file.size <= this.maxFileSize;
      });

      if (event.target.files.length > validFiles.length) {
        Swal.fire({
          title: this.$t('Chat.NotValidAttachments'),
          text: this.$t('Chat.NotValidAttachmentsText', { size: this.maxFileSize / 1024 / 1024 }),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
          buttonsStyling: false,
        });
      }

      this.$emit('file-added', validFiles);
      this.$refs['file-input'].value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-file-upload {
  &__input {
    position: absolute;
    opacity: 0;
  }
}
</style>
