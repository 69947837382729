<template>
  <li class="chat-message" :class="{ right: message.is_own }">
    <div class="conversation-list">
      <!-- <b-dropdown variant="white" menu-class="dropdown-menu-end">
              <template v-slot:button-content>
                <i class="bx bx-dots-vertical-rounded"></i>
              </template>
              <b-dropdown-item>Copy</b-dropdown-item>
              <b-dropdown-item>Save</b-dropdown-item>
              <b-dropdown-item>Forward</b-dropdown-item>
              <b-dropdown-item>Delete</b-dropdown-item>
            </b-dropdown> -->
      <div class="ctext-wrap">
        <!-- <div class="conversation-name">{{ name }}</div> -->
        <p class="chat-message-text">{{ message.message }}</p>

        <div v-if="message.attachments" class="chat-attachments">
          <div
            v-for="attached in message.attachments"
            :key="attached.id"
            :class="!isImage(attached.mime_type) ? 'w-100' : ''"
          >
            <image-zoom
              v-if="isImage(attached.mime_type)"
              :src="attached.url"
              :alt="attached.file_name"
              class="chat-attachments__image"
            />

            <div
              v-else-if="isPdf(attached.file_name)"
              class="chat-attachments__file"
              @click="openBlobFile(attached.url, attached.file_name)"
            >
              <img class="chat-attachments__file-icon" :src="require('@/assets/icons/pdf-icon.svg')" alt="doc-icon" />
              {{ attached.file_name }}
            </div>

            <div
              v-else-if="isDoc(attached.file_name)"
              class="chat-attachments__file"
              @click="openBlobFile(attached.url, attached.file_name)"
            >
              <img class="chat-attachments__file-icon" :src="require('@/assets/icons/doc-icon.svg')" alt="doc-icon" />
              {{ attached.file_name }}
            </div>

            <div
              v-else-if="isXls(attached.file_name)"
              class="chat-attachments__file"
              @click="openBlobFile(attached.url, attached.file_name)"
            >
              <img class="chat-attachments__file-icon" :src="require('@/assets/icons/xls-icon.svg')" alt="doc-icon" />
              {{ attached.file_name }}
            </div>
            <div v-else @click="openBlobFile(attached.url, attached.file_name)">{{ attached }}</div>
          </div>
        </div>

        <p v-if="message.created_at" class="chat-time mb-0">
          <i class="bx bx-time-five align-middle me-1"></i>
          {{ $moment(message.created_at).format('H:mm D.MM.Y') }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import ImageZoom from '@/components/common/ImageZoom';
import BlobMixin from '@/mixins/blob.mixin';

export default {
  name: 'ChatMessage',
  mixins: [BlobMixin],
  components: {
    ImageZoom,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isImage(mime_type) {
      return mime_type.includes('image/');
    },
    isDoc(name) {
      return name.endsWith('.doc') || name.endsWith('.docx');
    },
    isPdf(name) {
      return name.endsWith('.pdf');
    },
    isXls(name) {
      return name.endsWith('.xls') || name.endsWith('.xlsx');
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-message {
  display: flow-root;
}
.conversation-list {
  max-width: 75%;
}
.chat-attachments {
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
  &__image {
    max-width: 150px;
    margin: 0 6px 6px 0;
  }

  &__file {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    flex-grow: 1;
  }
  &__file-icon {
    margin-right: 8px;
    max-width: 24px;
    max-height: 18px;
  }
}

.chat-message-text {
  word-break: break-word;
}
</style>
