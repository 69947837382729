export default {
  methods: {
    openBlobFile(fileUrl, fileName = null) {
      return this.axios.get(fileUrl, { responseType: 'arraybuffer' }).then((response) => {
        const headers = response.headers;
        const blob = new Blob([response.data], { type: headers['content-type'] });
        const url = window.URL.createObjectURL(blob);

        if (fileName) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          window.open(url, '_blank').focus();
        }
      });
    },
  },
};
